import React from 'react'
import './NotFound.css'

export default function NotFound() {
    return (
        <section className="notFound">
            <h1>Path not found...you have to be mistaken!</h1>
        </section>
    )
}
